import React from "react"
import loadable from "@loadable/component"

import { useSiteContext } from "../common/SiteContext"
import { graphql, navigate, useStaticQuery } from "gatsby"
import {
  BackgroundImage,
  WsrvImage,
} from "@organic-return/foundation-react/src/components/Media"
import { EWsrvTransform } from "@organic-return/foundation-react/src/lib"

const Carousel = loadable(() => import("@organic-return/foundation-react/src/components/Carousel"), {
  resolveComponent: (components) => components.Carousel,
});
const EntrySearch = loadable(() => import("@organic-return/foundation-react/src/components/Search/EntrySearch"));

const ListingEntrySearch = ({ module }: any) => {
  const data = useStaticQuery(graphql`
    query ListingSearchEntryQuery {
      config: agilitySiteConfiguration {
        customFields {
          listingSearchPath
        }
      }
      entrySearch: allAgilityListingEntrySearch {
        nodes {
          contentID
          listings {            
            defaultImage {
              dimensions
              url
            }
          }
        }
      }
    }
  `)
  const siteContext = useSiteContext()
  const fields = module.customFields
  const listingSearchPath = fields.customListingSearchPath ?? data.config.customFields.listingSearchPath
  const component = (
    <div className="w-full max-w-screen-lg mx-auto">
      <EntrySearch
        title={fields.title}
        onSubmit={async filters => {
          const params = new URLSearchParams({
            filters: JSON.stringify(filters),
          })
          await navigate(
            listingSearchPath + "#" + params.toString() + "&sort=\"priceDESC\"&showMap=true"
          )
        }}
        {...siteContext.listingEntrySearchProps}
      />
    </div>
  )
  let itemsClass = "items-center"
  fields.searchPosition == "bottom" && (itemsClass = "items-end")
  fields.searchPosition == "top" && (itemsClass = "items-start")

  let heroCarouselItems: { isVideo: boolean; url: string }[] = []
  if (fields.backgroundVideo?.url || fields.backgroundListings) {
    fields.backgroundVideo &&
      heroCarouselItems.push({ isVideo: true, url: fields.backgroundVideo.url })

    if (fields.backgroundListings) {
      let node = data.entrySearch.nodes.find(
        (node: any) => node.contentID == module.contentID
      )
      let listings: any[] = node.listings || []
      listings.forEach(listing => {
        listing.defaultImage &&
          heroCarouselItems.push({
            isVideo: false,
            url: listing.defaultImage.url,
          })
      })
    }

    fields.backgroundImage?.url &&
      heroCarouselItems.push({
        isVideo: false,
        url: fields.backgroundImage.url,
      })
  }

  return (
    <div className="listing-entry-search">
      {heroCarouselItems.length > 0 && (
        <div
          className={`w-full h-screen relative flex flex-row ${itemsClass} justify-center`}
        >
          <Carousel className="h-full w-full">
            {heroCarouselItems.map((item: any, index: number) => (
              <>
                {item.isVideo && (
                  <video
                    key={index}
                    muted={true}
                    autoPlay={true}
                    loop={true}
                    className="w-full h-screen object-center object-cover"
                  >
                    <source src={item.url}></source>
                  </video>
                )}
                {!item.isVideo && (
                  <WsrvImage
                    key={index}
                    widthMax={2400}
                    urlParams={{
                      originURL: item.url,
                      preset: EWsrvTransform.cover16x9,
                    }}
                    imgProps={{
                      className:
                        "max-w-screen w-screen h-screen max-h-screen object-cover object-center",
                    }}
                  ></WsrvImage>
                )}
              </>
            ))}
          </Carousel>
          <div className="absolute inset-x-0 my-8 md:my-24 text-white">
            {component}
          </div>
        </div>
      )}
      {heroCarouselItems.length == 0 && fields.backgroundImage?.url && (
        <BackgroundImage
          src={fields.backgroundImage?.url}
          className={`listing-search-bg h-screen max-h-[1000px] text-white flex ${itemsClass} justify-center py-24`}
          sizesAndAspect={[
            {
              width: 420,
              aspect: EWsrvTransform.cover2x6,
            },
            {
              media: "min-width:420px",
              width: 640,
              aspect: EWsrvTransform.cover2x6,
            },
            {
              media: "min-width:640px",
              width: 768,
              aspect: EWsrvTransform.cover3x2,
            },
            {
              media: "min-width:768px",
              width: 1024,
              aspect: EWsrvTransform.cover3x2,
            },
            {
              media: "min-width:1024px",
              width: 1280,
              aspect: EWsrvTransform.cover16x9,
            },
            {
              media: "min-width:1280px",
              width: 1440,
              aspect: EWsrvTransform.cover16x9,
            },
            {
              media: "min-width:1440px",
              width: 1920,
              aspect: EWsrvTransform.cover16x9,
            },
            {
              media: "min-width:1920px",
              width: 2560,
              aspect: EWsrvTransform.cover16x9,
            },
          ]}
        >
          {component}
        </BackgroundImage>
      )}
      {heroCarouselItems.length == 0 &&
        !fields.backgroundImage?.url &&
        component}
    </div>
  )
}

export default ListingEntrySearch